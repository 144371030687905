import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { CLIENT_PATH, CUSTOMER_PATH } from '../constants/paths';
import EmptyView from './EmptyView';

const CustomerList = ({ customers }) => {

  const navigate = useNavigate();

  return (
    <>
    {
      customers.length === 0 && 
      <EmptyView
        icon="info circle"
        title="No Data Found"
        message="Please create an integration to start collecting user data."
        url={`${CLIENT_PATH}/${localStorage.getItem('clientID')}`}
        urlTitle="Create Integration"
      />
    }
    {customers.length > 0 &&
        <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content='Name' />
            <Table.HeaderCell content='Email' />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {customers.map((customer) => (
            <Table.Row key={customer.id}>
              <Table.Cell content={customer.name} onClick={() => navigate(CUSTOMER_PATH + '/' + customer.id)} />
              <Table.Cell content={customer.email} />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    }
    </>
  );
};
export default CustomerList