import React from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useNavigate } from 'react-router-dom';

const EmptyView = ({ icon, title, message, url, urlTitle }) => {
    const navigate = useNavigate();
  return (
    <Segment placeholder textAlign='center'>
        <Header icon>
            <Icon name={icon} />
            {title}
        </Header>
      <p>{message}</p>
      {url && (
        <Button primary onClick={() => navigate(url)}>
          {urlTitle}
        </Button>
      )}
    </Segment>
  );
};

export default EmptyView;