import { useEffect, useState } from 'react';
import ChatInbox from '../components/Chat';
import { Grid, Header } from 'semantic-ui-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import TicketList from '../components/TicketList';
import AuthLayout from '../layouts/AuthLayout';
import { useAuth } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import CustomerForm from '../components/CustomerForm';
import CustomersTickets from '../components/CustomerTickets';
import ErrorMessage from '../components/ErrorMessage';
import ConfigurationForm from '../components/ConfigurationForm';

const ConfigurationPage = () => {

  const [configuration, setConfiguration] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDoc(doc(db, 'configurations', localStorage.getItem("clientID"))).then((doc) => {
      setConfiguration({ id: doc.id, ...doc.data() });
    }, (error) => setError(error));
  }, []);

  return (
    <>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={16} style={{ display: 'flex', flexDirection: 'column' }}>
          <Header size='huge' content="Configuration" />
          {error && <ErrorMessage error={error} />}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={16} style={{ display: 'flex', flexDirection: 'column' }}>
          <ConfigurationForm configuration={configuration}/>
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default ConfigurationPage;
