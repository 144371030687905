import React, { useEffect, useState } from 'react';
import { Grid, Header, Item, List, Modal } from 'semantic-ui-react';
import CustomerTicketRow from './CustomerTicketRow';
import { db } from '../services/firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import TokenRow from './TokenRow';
import CreateTokenModal from './CreateTokenModal';
import ErrorMessage from './ErrorMessage';

const ClientTokensList = ({ clientID }) => {

    const [tokens, setTokens] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => { 
        const ref = query(
            collection(db, 'tokens'), 
            where('clientID', '==', clientID)
        );
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            setTokens(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
          }, (error) => setError(error));
        return () => unsubscribe()
    }, [clientID]);
    
    return (
        <>
        <Grid.Row columns={2}>
            <Grid.Column>
            <Header size='large'>Tokens</Header>
            </Grid.Column>
            <Grid.Column floated='right'>
                <CreateTokenModal clientID={clientID} />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
            <Grid.Column>
                {error && <ErrorMessage error={error} />}
                {tokens.length === 0 && <p>No tokens found</p>}
                {tokens.length > 0 &&
                    <Item.Group divided>
                        {tokens.map((token) => <TokenRow key={token.id} token={token} />)}
                    </Item.Group>
                }
        </Grid.Column>
        </Grid.Row>
    </>
    );
};
export default ClientTokensList