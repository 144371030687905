import React from 'react';
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage'; // Your component
import store from './redux/store';
import { useAuth } from './context/AuthContext';
import { Loader } from 'semantic-ui-react';
import { CUSTOMER_PATH, HOME_PATH, LOGIN_PATH, REGISTER_PATH, ACCOUNT_PATH, TICKET_PATH, INSTRUCTIONS_PATH, CLIENT_PATH } from './constants/paths';
import RegisterPage from './pages/RegisterPage';
import CustomersPage from './pages/CustomersPage';
import AccountPage from './pages/AccountPage';
import TicketsPage from './pages/TicketsPage';
import CustomerDetailPage from './pages/CustomerDetailPage';
import InstructionsPage from './pages/InstructionsPage';
import ClientsPage from './pages/ClientsPage';
import ClientDetailPage from './pages/ClientDetailPage';

export const AuthenticatedRoute = ({ auth, element }) => {
  if (auth.isLoading) return <Loader active />;
  if (!auth.currentUser) return <Navigate to={LOGIN_PATH} replace />;
  return element;
};

export const UnauthenticatedRoute = ({ auth, element }) => {
  if (auth.isLoading) return <Loader active />;
  if (auth.currentUser) return <Navigate to={HOME_PATH} replace />;
  return element;
};

function App() {

  const auth = useAuth();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path={HOME_PATH} element={<AuthenticatedRoute auth={auth} element={<TicketsPage />} />} />
          <Route path={LOGIN_PATH} element={<UnauthenticatedRoute auth={auth} element={<LoginPage />} />} />
          {/* <Route path={REGISTER_PATH} element={<UnauthenticatedRoute auth={auth} element={<RegisterPage />} />} /> */}
          <Route path={INSTRUCTIONS_PATH} element={<AuthenticatedRoute auth={auth} element={<InstructionsPage />} />} />

          <Route path={CUSTOMER_PATH}>
            <Route index element={<AuthenticatedRoute auth={auth} element={<CustomersPage />} />}/>
            <Route path=":customerID" element={<AuthenticatedRoute auth={auth} element={<CustomerDetailPage />} />}/>
          </Route>

          <Route path={CLIENT_PATH}>
            <Route index element={<AuthenticatedRoute auth={auth} element={<ClientsPage />} />}/>
            <Route path=":clientID" element={<AuthenticatedRoute auth={auth} element={<ClientDetailPage />} />}/>
          </Route>

          <Route path={TICKET_PATH}>
            <Route index element={<AuthenticatedRoute auth={auth} element={<TicketsPage />} />}/>
            <Route path=":ticketID" element={<AuthenticatedRoute auth={auth} element={<TicketsPage />} />}/>
          </Route>

          <Route path={ACCOUNT_PATH}>
            <Route index element={<AuthenticatedRoute auth={auth} element={<AccountPage />} />}/>
            <Route path="*" element={<AuthenticatedRoute auth={auth} element={<AccountPage />} />}/>
          </Route>
          </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;