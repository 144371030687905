import React, { useState } from 'react';
import { functions } from '../services/firebase';
import {Segment, Button, Message} from 'semantic-ui-react';
import ErrorMessage from '../components/ErrorMessage';
import { httpsCallable } from "firebase/functions";
import { useAuth } from '../context/AuthContext';
import UpgradePlanBanner from '../components/banners/UpgradePlanBanner';

const BillingPage = () => {
    const {currentUser} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const onCreateStripePortalButtonPressed = async () => {
        setIsLoading(true);
        try {
            console.log(window.location.href)
            const {data} = await httpsCallable(functions, 'createStripePortalLink')({
                returnURL: window.location.href,
            });
            window.location.href = data;
        } catch (error) {
            console.error('Error creating Stripe portal link:', error);
            setError(error);
        }
        setIsLoading(false);
    }
    
    return (
        <>
            <UpgradePlanBanner />
            <Segment>
            {error && <ErrorMessage error={error} />}
            <Message
                warning
                header="Manage your billing"
                content="Manage your billing and subscription details."
            />
            <Button primary loading={isLoading} onClick={onCreateStripePortalButtonPressed}>Manage Billing</Button>
        </Segment>
        </>
    );
}

export default BillingPage;