import React, { useState } from 'react';
import { Item, Confirm, Button } from 'semantic-ui-react';
import TokenInstructionsModal from './TokenInstructionsModal';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../services/firebase';

// delete from firestore
const TokenRow = ({ token }) => {

    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleDelete = async () => {
        try {
            console.log('Deleting token', token);
            await deleteDoc(doc(db, 'tokens', token.id));
        } catch (error) {
            console.error('Error deleting token', error);
        }
    }

    return (
        <Item>
            <Item.Content>
                <Item.Header content={token.platform} />
                <Item.Meta>{token.id}</Item.Meta>
                {token.domain && <Item.Content content={token.domain} />}
                <Item.Description>{JSON.stringify(token.timestamp)}</Item.Description>
                <Item.Extra>
                    <TokenInstructionsModal token={token} />
                    <Confirm 
                        open={confirmOpen}
                        content={`Are you sure you want to delete token ${token.id}?`}
                        confirmButton='Delete'
                        cancelButton='Cancel'
                        onCancel={() => setConfirmOpen(false)}
                        onConfirm={handleDelete}
                    />
                    <Button negative onClick={() => setConfirmOpen(true)}>Delete</Button>
                </Item.Extra>
            </Item.Content>
        </Item>
    )

}
export default TokenRow