import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [profile, setProfile] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setIsLoading(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!currentUser) {
      setProfile({});
      return;
    }

    const fetchProfile = async () => {
      const data = await getDoc(doc(db, 'profiles', currentUser.uid));
      setProfile(data.data() || {});
    };

    fetchProfile();
  }
  , [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, profile, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};