export const REGISTER_PATH = "/register";
export const LOGIN_PATH = "/login";
export const HOME_PATH = "/";
export const CUSTOMER_PATH = "/customer";
export const ACCOUNT_PATH = "/account";
export const TICKET_PATH = "/ticket";
export const INSTRUCTIONS_PATH = "/instructions";
export const CONFIGURATION_PATH = "/configuration";
export const CLIENT_PATH = "/client";
export const PLATFORM_PATH = "/platform";
export const BILLING_PATH = "/billing";

