import React, { useEffect, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useAuth } from '../context/AuthContext';
import { db } from '../services/firebase';
import { doc, setDoc } from 'firebase/firestore';
import ErrorMessage from './ErrorMessage';

const ProfileForm = ({ profile }) => {

    const [formData, setFormData] = useState({
        firstName: profile?.firstName ?? '',
        lastName: profile?.lastName ?? '',
    });
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setFormData({
            firstName: profile?.firstName ?? '',
            lastName: profile?.lastName ?? '',
        });
    }
    , [profile]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError(null);
        try {
            await setDoc(doc(db, 'profiles', profile.id), formData);
        } catch (error) {
            setError(error);
        }
        setIsSaving(false);
    }

    return (
        <Form>
            {error && <ErrorMessage error={error} />}
            <Form.Input
                label='First Name'
                name='firstName'
                placeholder='First Name'
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            />
            <Form.Input
                label='Last Name'
                name='lastName'
                placeholder='Last Name'
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            />
            <Button type='submit' primary onClick={handleSubmit} loading={isSaving}>Save</Button>
        </Form>
    )
}
export default ProfileForm