import React, { useState } from 'react';
import {Segment, Button, Message} from 'semantic-ui-react';

// Store whether to show or not in local storage
const DownloadMobileApp = () => {
    const [hide, setHide] = useState(localStorage.getItem('hideDownloadMobileApp'));

    if (hide) {
        return null;
    }

    const handleDismiss = () => {
        localStorage.setItem('hideDownloadMobileApp', true);
        setHide(true);
    }
    
    return (
        <Segment>
            <Message
                warning
                header="Download the mobile app"
                content="Download the mobile app to access your account on the go."
                onDismiss={handleDismiss}
            />
            <Button primary href="https://testflight.apple.com/join/ru5cGm3R">Download iOS App</Button>
        </Segment>
    );
}

export default DownloadMobileApp;