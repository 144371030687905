import React from 'react';
import { List } from 'semantic-ui-react';
import { TICKET_PATH } from '../constants/paths';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const CustomerTicketRow = ({ ticket, customer }) => {

    const navigate = useNavigate()

    const time = moment(ticket.lastMessageTimestamp?.toDate()).fromNow();

    return (
        <List.Item onClick={() => navigate(`${TICKET_PATH}/${ticket.id}`)}>
            <List.Content>
                <List.Header>{time}</List.Header>
                <List.Description>
                    {ticket.lastMessage}
                </List.Description>
            </List.Content>
        </List.Item>
    )

}
export default CustomerTicketRow