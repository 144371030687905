import { useEffect, useState } from 'react';
import ChatInbox from '../components/Chat';
import { Grid, Header } from 'semantic-ui-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import TicketList from '../components/TicketList';
import AuthLayout from '../layouts/AuthLayout';
import { useAuth } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import CustomerForm from '../components/CustomerForm';
import CustomersTickets from '../components/CustomerTickets';
import ErrorMessage from '../components/ErrorMessage';

const CustomerDetailPage = () => {
  const { customerID } = useParams();
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!customerID) {
      return;
    }
    getDoc(doc(db, 'customers', customerID)).then((doc) => {
      setCustomer({ id: doc.id, ...doc.data() });
    }, (error) => setError(error));
  }, [customerID]);

  return (
    <AuthLayout>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={4} style={{ display: 'flex', flexDirection: 'column' }}>
          {error && <ErrorMessage error={error} />}
            <Header as='h2'>Customer Details</Header>
            <p>Customer ID: {customerID}</p>
            <p>Customer Name: {customer?.name}</p>
            <p>Customer Email: {customer?.email}</p>
            <p>Customer Phone: {customer?.phone}</p>
        </Grid.Column>
        <Grid.Column width={8}  style={{ display: 'flex', flexDirection: 'column' }}>
          {customer && <CustomerForm customer={customer}/>}
        </Grid.Column>
        <Grid.Column width={4}  style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomersTickets customerID={customerID}/>
        </Grid.Column>
        </Grid.Row>
    </AuthLayout>
  );
}

export default CustomerDetailPage;
