import React from 'react';
import { Grid, Header, Menu } from 'semantic-ui-react';
import { NavLink, Route, Routes } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout'; // Adjust this import path as necessary
import { ACCOUNT_PATH, BILLING_PATH, CONFIGURATION_PATH } from '../constants/paths'; // Ensure this constant is correctly defined in your constants
import ConfigurationPage from './ConfigurationPage';
import ChangePasswordForm from '../components/ChangePasswordForm';
import ProfilePage from './ProfilePage';
import BillingPage from './BillingPage';

const AccountPage = () => {
  return (
    <AuthLayout>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={3} style={{ display: 'flex', flexDirection: 'column' }}>
          <Menu vertical>
            <Menu.Item as={NavLink} to={ACCOUNT_PATH} content='Account' />
            <Menu.Item as={NavLink} to={`${ACCOUNT_PATH}${BILLING_PATH}`} content='Billing' />
            <Menu.Item as={NavLink} to={`${ACCOUNT_PATH}${CONFIGURATION_PATH}`} content='Configuration' />
            <Menu.Item as={NavLink} to={`${ACCOUNT_PATH}/change-password`} content='Change Password' />
          </Menu>
        </Grid.Column>
        <Grid.Column width={13} style={{ display: 'flex', flexDirection: 'column' }}>
          <Routes>
            <Route path='/' element={<ProfilePage />} />
            <Route path={CONFIGURATION_PATH} element={<ConfigurationPage />} />
            <Route path={BILLING_PATH} element={<BillingPage />} />
            <Route path='/change-password' element={<ChangePasswordForm />} />
          </Routes>
        </Grid.Column>
      </Grid.Row>
    </AuthLayout>
  );
};

export default AccountPage;