import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Segment, Modal, Item, Card } from 'semantic-ui-react';

const TokenInstructionsModal = ({ token }) => {

  const { clientID } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [subtype, setSubtype] = useState(null);

  const librarySource = `https://d2huomdiop2bs1.cloudfront.net/chat-module.js`;

  const scriptSnippet = `<script src="${librarySource}" clientID="${token.id}" clientToken="${token.clientToken}"></script>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(scriptSnippet).then(() => {
      alert('Code snippet copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }

  return (
    <Modal 
      onClose={() => setIsOpen(false)}
      closeIcon={true}
      open={isOpen}
      trigger={<Button primary onClick={() => setIsOpen(true)}>Show Integration Code</Button>}
      >
        <Modal.Header>Integration Code</Modal.Header>
        <Modal.Content style={{ overflow: "hidden", wordBreak: "break-all" }}>

            {token.platform === 'web' &&
            <>
            {subtype === null &&
            <Card.Group>
              <Card onClick={() => setSubtype('javascript')} color={subtype === 'javascript' ? 'blue' : null}>
                <Card.Content>
                  <Card.Header>JavaScript</Card.Header>
                  <Card.Description>
                    Integrate our chat module into your website using JavaScript.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card onClick={() => setSubtype('gatsby')} color={subtype === 'gatsby' ? 'blue' : null}>
                <Card.Content>
                  <Card.Header>Gatsby</Card.Header>
                  <Card.Description>
                    Integrate our chat module into your Gatsby website.
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
}
            {
              subtype === 'javascript' && 
              <>
                <p>To integrate our JavaScript module into your website, simply add the following script tag to the HTML of your webpage:</p>
                <Segment inverted color='blue'>
                  {`<script
                    src="${librarySource}"
                    clientID="${clientID}"
                    clientToken="${token.clientToken}"
                  ></script>`}
                </Segment>
              </>
            }
            {
              subtype === 'gatsby' && 
              <>
                <p>To integrate our Gatsby module into your website, please do the following:</p>
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header>Step 1</Item.Header>
                      <Item.Description>
                        Install the plugin via npm:
                        <Segment inverted color='blue'>
                          {`npm install gatsby-plugin-support-bubble`}
                        </Segment>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Item.Content>
                      <Item.Header>Step 2</Item.Header>
                      <Item.Description>
                        Add the plugin to your gatsby-config.js:
                        <Segment inverted color='blue'>
                          {`
                            resolve: "gatsby-plugin-support-bubble",
                            options: {
                              clientID: "${clientID}",
                              clientToken: "${token.clientToken}"
                            }
                          `}
                        </Segment>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </>
            }
            </>
}
            {token.platform === 'ios' &&
            <>
              <p>To integrate our iOS module into your app, complete the following steps:</p>
              <Item.Group>
                <Item>
                  <Item.Content>
                    <Item.Header>Step 1</Item.Header>
                    <Item.Description>
                      Add the following via SPM 
                      <Segment inverted color='blue'>
                        {`
                          .package(url: "https://github.com/AdamBCo/support-bubble-ios", from: "1.0.0")
                        `}
                      </Segment>
                    </Item.Description>
                  </Item.Content>
                </Item>
                <Item>
                  <Item.Content>
                    <Item.Header>Step 2</Item.Header>
                    <Item.Description>
                      Import the module in your AppDelegate.swift:
                      <Segment inverted color='blue'>
                        {`import SupportBubble`}
                      </Segment>
                    </Item.Description>
                  </Item.Content>
                </Item>
                <Item>
                  <Item.Content>
                    <Item.Header>Step 3</Item.Header>
                    <Item.Description>
                      Add the following to your AppDelegate.swift:
                      <Segment inverted color='blue'>
                        {`SupportBubble.shared.clientID = "${clientID}"`}
                        <br />
                        {`SupportBubble.shared.clientToken = "${token.clientToken}"`}
                      </Segment>
                    </Item.Description>
                  </Item.Content>
                </Item>
                <Item>
                  <Item.Content>
                    <Item.Header>Step 4</Item.Header>
                    <Item.Description>
                      Call the following method to show the chat view:
                      <Segment inverted color='blue'>
                        {`SupportBubble.shared.showTicketsView()`}
                      </Segment>
                    </Item.Description>
                  </Item.Content>
                  </Item>
              </Item.Group>
            </>
            }
        </Modal.Content>
        <Modal.Actions>
          {subtype !== null &&
          <Button primary onClick={() => setSubtype(null)}>Back</Button>
}
          {token.platform === 'web' && subtype === "javascript" &&
            <Button positive onClick={copyToClipboard}>Copy to Clipboard</Button>
          }
            <Button onClick={() => setIsOpen(false)}>Close</Button>
        </Modal.Actions>
    </Modal>
  );
}

export default TokenInstructionsModal;
