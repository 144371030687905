import React from 'react';
import { NavLink } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { CLIENT_PATH, CUSTOMER_PATH } from '../constants/paths';

const ClientList = ({ clients }) => (
  <List selection divided relaxed>
    {clients.map((client) => (
      <List.Item as={NavLink} to={`${CLIENT_PATH}/${client.id}`} key={client.id}>
        <List.Content>
          <List.Header>{client.name}</List.Header>
        </List.Content>
      </List.Item>
    ))}
  </List>
);
export default ClientList