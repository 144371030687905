import React, { useEffect, useState } from 'react';
import { Button, Form, Image } from 'semantic-ui-react';
import axiosInstance from '../services/axios';
import { useAuth } from '../context/AuthContext';
import InputColor from 'react-input-color';
import ErrorMessage from './ErrorMessage';

const ConfigurationForm = ({ configuration }) => {

    const {currentUser} = useAuth();
    const [formData, setFormData] = useState({
        header: configuration?.header ?? 'Support Tickets',
        subheader: configuration?.subheader ?? 'How can we help you today?',
        iconUrl: configuration?.iconUrl ?? '',
        primaryColor: configuration?.primaryColor ?? '',
        secondaryColor: configuration?.secondaryColor ?? '',
        agentName: configuration?.agentName ?? '',
        agentEmail: configuration?.agentEmail ?? '',
        agentAvatar: configuration?.agentAvatar ?? '',
    });
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setFormData({
            header: configuration?.header ?? 'Support Tickets',
            subheader: configuration?.subheader ?? 'How can we help you today?',
            iconUrl: configuration?.iconUrl ?? '',
            primaryColor: configuration?.primaryColor ?? '',
            secondaryColor: configuration?.secondaryColor ?? '',
            agentName: configuration?.agentName ?? '',
            agentEmail: configuration?.agentEmail ?? '',
            agentAvatar: configuration?.agentAvatar ?? '',
        });
    }
    , [configuration]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError(null);
        try {
            await axiosInstance.put(`/v1/configurations/${localStorage.getItem("clientID")}`, formData);
        } catch (error) {
            setError(error);
        }
        setIsSaving(false);
    }

    return (
        <Form>
            {error && <ErrorMessage error={error} />}
            <Form.Input
                label='Header'
                name='header'
                placeholder='Header'
                value={formData.header}
                onChange={(e) => setFormData({ ...formData, header: e.target.value })}
            />
            <Form.Input
                label='Subheader'
                name='subheader'
                placeholder='Subheader'
                value={formData.subheader}
                onChange={(e) => setFormData({ ...formData, subheader: e.target.value })}
            />
            <Form.Input
                label='Icon URL'
                name='iconUrl'
                placeholder='Icon URL'
                value={formData.iconUrl}
                onChange={(e) => setFormData({ ...formData, iconUrl: e.target.value })}
            />
            <Form.Field>
                <label>Primary Color</label>
                <InputColor
                    initialValue={formData.primaryColor}
                    onChange={(value) => setFormData({ ...formData, primaryColor: value.hex })}
                />
            </Form.Field>
            <Form.Input
                label='Agent Name'
                name='agentName'
                placeholder='Agent Name'
                value={formData.agentName}
                onChange={(e) => setFormData({ ...formData, agentName: e.target.value })}
            />
            <Form.Input
                label='Agent Email'
                name='agentEmail'
                placeholder='Agent Email'
                value={formData.agentEmail}
                onChange={(e) => setFormData({ ...formData, agentEmail: e.target.value })}
            />
            <Form.Input
                label='Agent Avatar'
                name='agentAvatar'
                placeholder='Agent Avatar'
                value={formData.agentAvatar}
                onChange={(e) => setFormData({ ...formData, agentAvatar: e.target.value })}
            />
            <Button type='submit' primary onClick={handleSubmit} loading={isSaving}>Save</Button>
        </Form>
    )
}
export default ConfigurationForm