import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './reducers'; // You'll create this in the next step

// const initialState = {};
// const middleware = [thunk];

export const store = configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware({
    //     serializableCheck: false,
    //   }).concat(axiosMiddleware(client, middlewareConfig)),
    // enhancers: (getDefaultEnhancers) =>
    //   getDefaultEnhancers().concat(sentryReduxEnhancer),
  });

export default store;
