import { useEffect, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import ErrorMessage from '../components/ErrorMessage';
import ConfigurationForm from '../components/ConfigurationForm';
import ProfileForm from '../components/ProfileForm';
import { useAuth } from '../context/AuthContext';

const ProfilePage = () => {

  const { currentUser } = useAuth();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDoc(doc(db, 'profiles', currentUser.uid )).then((doc) => {
      setProfile({ id: doc.id, ...doc.data() });
    }, (error) => setError(error));
  }, []);

  return (
    <>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={16} style={{ display: 'flex', flexDirection: 'column' }}>
          <Header size='huge' content="Profile" />
          {error && <ErrorMessage error={error} />}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={16} style={{ display: 'flex', flexDirection: 'column' }}>
          <ProfileForm profile={profile} />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default ProfilePage;
