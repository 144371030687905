import React from 'react';
import { Dropdown, Grid, Header, Segment} from 'semantic-ui-react';
import { db } from '../services/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { NavLink } from 'react-router-dom';
import { CUSTOMER_PATH } from '../constants/paths';

const ChatHeader = ({ticket, currentCustomer}) => {
    if (!ticket) {
        return null;
    }

    const handleStatusChange = async (status) => {
        try {
            await updateDoc(doc(db, 'tickets', ticket.id), { status });
        } catch (error) {
            console.error('Error updating ticket status', error);
        }
    }

    return (
        <Segment>
        <Grid>
            <Grid.Row>
                <Grid.Column width={15}>
                    <Header size='huge'>
                        <Header.Content 
                            as={NavLink} 
                            to={CUSTOMER_PATH + '/' + currentCustomer.id}
                            content={currentCustomer && currentCustomer.name} 
                        />
                        <Header.Subheader>
                            {currentCustomer && currentCustomer.email} - {ticket && ticket.status}
                        </Header.Subheader>
                    </Header>
                </Grid.Column>
                <Grid.Column floated='right' width={1}>
                    <Dropdown floated={'right'} icon='info' direction='left'>
                        <Dropdown.Menu>
                            {(ticket.status === 'open' || ticket.status === "snoozed") && <Dropdown.Item text='Close Ticket' onClick={() => handleStatusChange("closed")} />}
                            {ticket.status === 'open' && <Dropdown.Item text='Snooze Ticket' onClick={() => handleStatusChange("snoozed")}/>}
                            {(ticket.status === 'closed' || ticket.status === "snoozed") && <Dropdown.Item text='Reopen Ticket' onClick={() => handleStatusChange("open")}/>}
                        </Dropdown.Menu>
                    </Dropdown>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </Segment>
    );
};

export default ChatHeader;