import { useEffect, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import AuthLayout from '../layouts/AuthLayout';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../components/ErrorMessage';
import ClientTokensList from '../components/ClientTokensList';

const ClientDetailPage = () => {
  const { clientID } = useParams();
  const [client, setClient] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!clientID) {
      return;
    }
    getDoc(doc(db, 'clients', clientID)).then((doc) => {
      setClient({ id: doc.id, ...doc.data() });
    }, (error) => setError(error));
  }, [clientID]);

  return (
    <AuthLayout>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={4} style={{ display: 'flex', flexDirection: 'column' }}>
          {error && <ErrorMessage error={error} />}
            <Header as='h2'>Client Details</Header>
            <p>Client ID: {clientID}</p>
            <p>Client Name: {client?.name}</p>
        </Grid.Column>
        <Grid.Column width={12}>
            <ClientTokensList clientID={clientID} />
        </Grid.Column>
        </Grid.Row>
    </AuthLayout>
  );
}

export default ClientDetailPage;
