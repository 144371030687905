import { FirebaseError } from 'firebase/app';
import React from 'react';
import { Message } from 'semantic-ui-react';

const ErrorMessage = ({ error }) => {
    if (!error) return null;

    let title = 'Error';
    let message = error.message;
    if (error instanceof FirebaseError) {
        title = 'Firebase Error';
        message = error.message;
    } else if (error.code === 'permission-denied') {
        message = 'You do not have permission to view this page';
    }

    return (
        <Message negative header={title} content={message} />
    )

}
export default ErrorMessage