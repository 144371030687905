import { useEffect, useState } from 'react';
import ClientList from '../components/ClientList';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { auth, db } from '../services/firebase';
import AuthLayout from '../layouts/AuthLayout';
import { useAuth } from '../context/AuthContext';
import ClientForm from '../components/ClientForm';

const ClientsPage = () => {

  const {currentUser} = useAuth();
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => { 
    const ref = query(collection(db, 'clients'), where('ownerID', '==', currentUser.uid));
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const clients = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
        setClients(clients);
      }, (error) => setError(error));
    return () => unsubscribe()
  }, []);

  return (
    <AuthLayout>
        <Grid.Row columns={2}>
            <Grid.Column>
                <Header as='h2'>Clients</Header>
            </Grid.Column>
            <Grid.Column floated='right'>
                <Modal trigger={<Button content="Add Client" floated='right' />}>
                    <Modal.Header>Add Client</Modal.Header>
                    <Modal.Content>
                        <ClientForm />
                    </Modal.Content>
                </Modal>
            </Grid.Column>
        </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <ClientList clients={clients ?? []}/>
        </Grid.Column>
      </Grid.Row>
    </AuthLayout>
  );
}

export default ClientsPage;
