import React, { useEffect, useState } from 'react';
import { Header, List } from 'semantic-ui-react';
import CustomerTicketRow from './CustomerTicketRow';
import { db } from '../services/firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import ErrorMessage from './ErrorMessage';

const CustomersTickets = ({ customerID }) => {
    const [tickets, setTickets] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => { 
        const ref = query(
            collection(db, 'tickets'), 
            where('customerID', '==', customerID),
            where('clientID', '==', localStorage.getItem('clientID'))
        );
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            setTickets(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
          }, (error) => setError(error));
        return () => unsubscribe()
    }, [customerID]);
    
    return (
    <>
        <Header size='large' dividing>Tickets</Header>
        {error && <ErrorMessage error={error} />}
        <List selection divided celled>
            {tickets.map((ticket) => <CustomerTicketRow key={ticket.id} ticket={ticket} />)}
        </List>
    </>
    );
};
export default CustomersTickets