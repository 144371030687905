import React, { useState, useEffect } from 'react';
import { Button, Form, Header, Segment, Comment, Grid } from 'semantic-ui-react';
import { collection, onSnapshot, where, query, getDoc, doc, orderBy, limit } from "firebase/firestore";
import { db } from "../services/firebase";
import axios from '../services/axios'; // The file you created   
import ChatMessage from './ChatMessage';
import ErrorMessage from './ErrorMessage';
import { useAuth } from '../context/AuthContext';
import ChatHeader from './ChatHeader';

const Chat = ({ticketID}) => {
    const {currentUser } = useAuth();
    const [currentCustomer, setCurrentCustomer] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);
    const [ticket, setTicket] = useState(null);
    const [error, setError] = useState(null);

    const handleSendMessage = async () => {
        setSendingMessage(true);
        try {
            await axios.post('/v1/messages', { 
                message,
                ticketID: ticket.id,
                clientID: ticket.clientID
            });
            setMessage('');
        } catch (error) {
            setError(error);
        }
        setSendingMessage(false);
    };

    useEffect(() => { 
        if (!ticketID) {
            return;
        }
        const fetchTicket = async () => {
            const data = await getDoc(doc(db, 'tickets', ticketID));
            setTicket({id: data.id, ...data.data()});
        }
        fetchTicket();
    }, [ticketID]);
    

    useEffect(() => { 
        if (!ticket) {
            return;
        }

        const fetchUser = async () => {
            const data = await getDoc(doc(db, 'customers', ticket.customerID));
            setCurrentCustomer({id: data.id, ...data.data()});
        }
        fetchUser();

        const ref = query(
            collection(db, 'messages'), 
            where('ticketID', '==', ticket.id),
            where('clientID', '==', ticket.clientID),
            orderBy('timestamp', 'asc'), 
            limit(100)
        );
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            setMessages(snapshot.docs.map(doc => ({id: doc.id, ...doc.data()})));
        }, (error) => setError(error));
        return () => unsubscribe()
    }, [ticket]);

    if (!ticketID) {
        return (
            <Grid.Row style={{ flexGrow: 1 }}>
            <Segment>
                <Header as='h3'>Select a ticket to start chatting</Header>
            </Segment>
            </Grid.Row>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
            <ChatHeader ticket={ticket} currentCustomer={currentCustomer} />
            {error && <ErrorMessage error={error} />}
            <div style={{ overflowY: 'auto', flexGrow: 1 }}>
            <Comment.Group>
                {messages.map((msg) => <ChatMessage 
                    key={msg.id} 
                    chatMessage={msg} 
                    currentUser={currentUser}
                    currentCustomer={currentCustomer}
                />)}
            </Comment.Group>
            </div>
        <Form>
            <Form.Input
                placeholder='Enter message'
                value={message}
                onChange={e => setMessage(e.target.value)}
            />
            <Button 
                content='Send'
                onClick={handleSendMessage}
                disabled={sendingMessage}
                loading={sendingMessage}
            />
        </Form>
        </div>
    );
};

export default Chat;