// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBOO1HjkHEdxNFNG4SJppnXldfYiusGlsk",
  authDomain: "customer-support-7c4e8.firebaseapp.com",
  projectId: "customer-support-7c4e8",
  storageBucket: "customer-support-7c4e8.appspot.com",
  messagingSenderId: "349698789343",
  appId: "1:349698789343:web:f4b9b9da198ff3cdc77e6a",
  measurementId: "G-YNX1CQ2QBE"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);;
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in');
  } else {
    console.log('User is signed out');
  }
});
