import React, { useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import axiosInstance from '../services/axios';
import ErrorMessage from './ErrorMessage';

const CreateTokenModal = ({ token, clientID }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        platform: token?.platform ?? 'web',
        website: token?.website ?? '',
    });
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            if (token) {
            await axiosInstance.put(`/v1/tokens/${token.id}`, formData);
            } else {
                await axiosInstance.post(`/v1/tokens`, {
                    ...formData,
                    clientID,
                });
            }
            setFormData({ platform: 'web', website: '' });
            setIsOpen(false);
        } catch (error) {
            setError(error);
        }
        setIsSaving(false);
    }

    return (
        <Modal
            onClose={() => setIsOpen(false)}
            closeIcon={true}
            open={isOpen}
            trigger={<Button 
                primary 
                floated='right'
                onClick={() => setIsOpen(true)}>{token ? 'Edit' : 'Add'} Token</Button>
            }
        >
            <Modal.Header>{token ? 'Edit' : 'Add'} Token</Modal.Header>
            <Modal.Content>
            <Form>
                {error && <Form.Field><ErrorMessage error={error} /></Form.Field>}
                <Form.Select
                    label='Platform'
                    name='platform'
                    placeholder='Platform'
                    value={formData.platform}
                    onChange={(e, { value }) => setFormData({ ...formData, platform: value })}
                    options={[
                        { key: 'web', value: 'web', text: 'Web' },
                        { key: 'ios', value: 'ios', text: 'iOS' },
                        { key: 'android', value: 'android', text: 'Android' },
                    ]}
                />
                {
                    formData.platform === 'web' &&
                    <Form.Input
                        label='Website'
                        name='website'
                        placeholder='Website'
                        value={formData.website}
                        onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                    />
                }
            </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={handleSubmit} loading={isSaving}>Save</Button>
                <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    );
}
export default CreateTokenModal