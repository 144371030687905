import { useState } from 'react';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { REGISTER_PATH } from '../constants/paths';
import logo from '../images/logo.png';

const LoginPage = () => {

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log(userCredential)
    } catch (error) {
      console.error(error);
    }
  };

  // Add logo
  return (
    <Grid textAlign='center' style={{ height: '100vh', backgroundColor: "#0B1323", margin: 0 }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src={logo} size='tiny' centered />
        <Header as='h2' inverted textAlign='center'>
          Login to your account
        </Header>
        <Form size='large' onSubmit={e => onSubmit(e)}>
          <Segment stacked>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='E-mail address'
              suggested="email"
              name='email'
              value={email}
              onChange={e => onChange(e)}
              required
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              suggested="current-password"
              name='password'
              value={password}
              onChange={e => onChange(e)}
              type='password'
              required
            />

            <Button primary fluid size='large'>
              Login
            </Button>
          </Segment>
        </Form>
        {/* <Message onClick={() => navigate(REGISTER_PATH)}>
          Need an account?
        </Message> */}
      </Grid.Column>
    </Grid>
  );
}

export default LoginPage;
