import { useEffect, useState } from 'react';
import CustomerList from '../components/CustomerList';
import { Grid, Header } from 'semantic-ui-react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { auth, db } from '../services/firebase';
import AuthLayout from '../layouts/AuthLayout';
import { useAuth } from '../context/AuthContext';

const CustomersPage = () => {

  const clientID = localStorage.getItem('clientID');
  const {currentUser} = useAuth();
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => { 
    const ref = query(collection(db, 'customers'), where('clientID', '==', clientID));
    const unsubscribe = onSnapshot(ref, (snapshot) => {
        const customers = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
        setCustomers(customers);
      }, (error) => setError(error));
    return () => unsubscribe()
  }, []);

  return (
    <AuthLayout>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as='h2'>Customers</Header>
          <CustomerList customers={customers}/>
        </Grid.Column>
      </Grid.Row>
    </AuthLayout>
  );
}

export default CustomersPage;
