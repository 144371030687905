import { addDoc, collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Button, Form, Header, Modal } from 'semantic-ui-react';
import { db } from '../services/firebase';
import ErrorMessage from './ErrorMessage';
import axiosInstance from '../services/axios';

const SelectClientModal = ({ open, currentUser }) => {

    const [clients, setClients] = useState([]);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
    });
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => { 
        const ref = query(collection(db, 'clients'), where('ownerID', '==', currentUser.uid));
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            const clients = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setClients(clients);
          }, (error) => setError(error));
        return () => unsubscribe()
      }, [open, currentUser.uid]);

    const onClientSelect = (client) => {
        localStorage.setItem('clientID', client.id);
        window.location.reload();
    }

    const onCreateClient = async () => {
        setError(null);
        setIsSaving(true);
        try {
            const {data} = await axiosInstance.post('/v1/clients', { name: formData.name });
            localStorage.setItem('clientID', data.id);
            window.location.reload();
        } catch (error) {
            setError(error);
        }
        setIsSaving(false);
    }

    return (
        <Modal
            open={open}
        >
            {clients.length === 0 && <Modal.Header content='Getting Started' />}
            {clients.length > 0 && <Modal.Header content='Select Company' />}
            <Modal.Content>
                {error && <ErrorMessage error={error} />}
                {clients.map(client => (
                    <Button key={client.id} content={client.name} onClick={() => onClientSelect(client)} />
                ))}
                {clients.length === 0 &&
                <>
                    <Header content='Your company name' />
                    <Form onSubmit={onCreateClient}>
                        <Form.Input 
                        size='large'
                            name='name'
                            placeholder='Company Name'
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                    </Form>
                </>
                }
            </Modal.Content>
            {
                clients.length === 0 && 
                <Modal.Actions>
                    <Button primary loading={isSaving} onClick={onCreateClient}>Create</Button>
                </Modal.Actions>
            }
        </Modal>
    );
}
export default SelectClientModal