import { getAuth } from 'firebase/auth';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Grid, Menu, Confirm, Dropdown } from 'semantic-ui-react';
import { ACCOUNT_PATH, CLIENT_PATH, CUSTOMER_PATH, INSTRUCTIONS_PATH, TICKET_PATH } from '../constants/paths';
import logo from '../images/logo.png';
import SelectClientModal from '../components/SelectClientModal';
import { useAuth } from '../context/AuthContext';
import UpgradePlanBanner from '../components/banners/UpgradePlanBanner';
import DownloadMobileApp from '../components/banners/DownloadMobileApp';

// Check if there is a clientID in the local storage, if not show modal to select client

const AuthLayout = ({ title, description, children }) => {

    const clientID = localStorage.getItem('clientID');
    const navigate = useNavigate();
    const {currentUser, profile} = useAuth();
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

    const handleLogout = () => {
        // logout user
        localStorage.removeItem('clientID');    
        const auth = getAuth();
        auth.signOut();
    }

    return (
        <>
        <Confirm
            open={showLogoutConfirmation}
            onCancel={() => setShowLogoutConfirmation(false)}
            onConfirm={handleLogout}
        />
        <SelectClientModal open={clientID == null} currentUser={currentUser} />
        <Menu fixed='top' inverted>
            <Menu.Item onClick={() => navigate(TICKET_PATH)} style={{ height: '105%' }}>
                <img alt="logo" src={logo} style={{ marginTop: '4px' }} />
            </Menu.Item>
            <Menu.Item as={NavLink} content={"Tickets"} to={TICKET_PATH} />
            <Menu.Item as={NavLink} content={"Customers"} to={CUSTOMER_PATH} />
        
            <Menu.Menu position='right'>
                {
                    clientID &&
                    <Menu.Item as={NavLink} content={"Instructions"} to={`${CLIENT_PATH}/${clientID}`} />
                }
                <Dropdown item text='Account'>
                    <Dropdown.Menu>
                        <Dropdown.Item as={NavLink} to={ACCOUNT_PATH} content='Profile' />
                        <Dropdown.Item content='Logout' onClick={() => setShowLogoutConfirmation(true)} />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
        <Grid stackable style={{marginTop: "40px", marginLeft: "16px", marginRight: "16px"}}>
            <Grid.Row columns={1}>
                <Grid.Column>
                <DownloadMobileApp />
                <UpgradePlanBanner profile={profile} />
                </Grid.Column>
            </Grid.Row>
            {children}
        </Grid>
        </>
    );
}

export default AuthLayout;
