import React, { useEffect, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useAuth } from '../context/AuthContext';
import { updatePassword } from "firebase/auth";
import ErrorMessage from './ErrorMessage';

const ChangePasswordForm = ({ configuration }) => {

    const {currentUser} = useAuth();
    const [formData, setFormData] = useState({
        password: '',
    });
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setFormData({
        });
    }
    , [configuration]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError(null);
        try {
            await updatePassword(currentUser, formData.password);
        } catch (error) {
            setError(error);
        }
        setIsSaving(false);
    }

    return (
        <Form>
            {error && <ErrorMessage error={error} />}
            <Form.Input
                label='Password'
                name='password'
                placeholder='Password'
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            />
            <Button type='submit' primary onClick={handleSubmit} loading={isSaving}>Save</Button>
        </Form>
    )
}
export default ChangePasswordForm