import { useEffect, useState } from 'react';
import ChatInbox from '../components/Chat';
import { Grid } from 'semantic-ui-react';
import { collection, onSnapshot, query, where, getDoc, doc, orderBy } from 'firebase/firestore';
import { db } from '../services/firebase';
import TicketList from '../components/TicketList';
import AuthLayout from '../layouts/AuthLayout';
import { useAuth } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../components/ErrorMessage';

const TicketsPage = () => {

  const clientID = localStorage.getItem('clientID');
  const { ticketID } = useParams();
  const [tickets, setTickets] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('open');

  useEffect(() => {
    const ref = query(
      collection(db, 'tickets'), 
      where('clientID', '==', clientID),
      where('status', '==', selectedStatus),
      orderBy('lastMessageTimestamp', 'desc')
    );
    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const tickets = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTickets(tickets);
    }, (error) => setError(error));
    return () => unsubscribe()
  }, [selectedStatus, clientID]);

  useEffect(() => {
    const customerIDs = tickets.map(ticket => ticket.customerID).filter(customerID => !customers.find(customer => customer.id === customerID));
    if (customerIDs.length === 0) return;
    Promise.all(customerIDs.map(async (customerID) => {
      return await getDoc(doc(db, 'customers', customerID));
    })).then((docs) => {
      setCustomers(docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });
  }, [tickets]);

  return (
    <AuthLayout>
      <Grid.Row style={{ flexGrow: 1 }}>
        <Grid.Column width={3} style={{ display: 'flex', flexDirection: 'column' }}>
          <TicketList 
            status={selectedStatus}
            tickets={tickets} 
            customers={customers} 
            onStatusSelected={setSelectedStatus}
          />
        </Grid.Column>
        <Grid.Column width={13}  style={{ display: 'flex', flexDirection: 'column' }}>
          {error && <ErrorMessage error={error} />}
          <ChatInbox ticketID={ticketID} />
        </Grid.Column>
      </Grid.Row>
    </AuthLayout>
  );
}

export default TicketsPage;
