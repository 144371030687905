import React from 'react';
import { NavLink } from 'react-router-dom';
import { Header, List, Label } from 'semantic-ui-react';
import { CLIENT_PATH } from '../constants/paths';
import TicketRow from './TicketRow';

const TicketList = ({ tickets, customers, status, onStatusSelected }) => {
  const customer = (customerID) => (customers ?? []).find((customer) => customer.id === customerID)
  return (
    <>
    <Header size='large' dividing>Tickets</Header>
    <Label.Group>
      <Label as='a' color={status === "open" ? 'blue': 'white'} onClick={() => onStatusSelected("open")} >Open</Label>
      <Label as='a' color={status === "snoozed" ? 'blue': 'white'} onClick={() => onStatusSelected("snoozed")} >Snoozed</Label>
      <Label as='a' color={status === "closed" ? 'blue': 'white'} onClick={() => onStatusSelected("closed")} >Closed</Label>
    </Label.Group>
    {tickets.length === 0 && <>
    <Header size='medium'>No tickets found</Header>
      <p>Click <NavLink to={`${CLIENT_PATH}/${localStorage.getItem('clientID')}`}>here</NavLink> to create integration</p>
    </>}
    <List selection divided celled>
      {tickets.map((ticket) => <TicketRow key={ticket.id} ticket={ticket} customer={customer(ticket.customerID)} />)}
    </List>
    </>
  );
};
export default TicketList