import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api'
});

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    user.getIdToken().then((token) => {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    });
  } else {
    axiosInstance.defaults.headers.common['Authorization'] = null;
  }
});

export default axiosInstance;