
import { Button, Container, Grid, Header, Segment } from 'semantic-ui-react';
import AuthLayout from '../layouts/AuthLayout';
import { useAuth } from '../context/AuthContext';
import ConfigurationPage from './ConfigurationPage';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import ConfigurationForm from '../components/ConfigurationForm';

const InstructionsPage = () => {

  const librarySource = `https://d2huomdiop2bs1.cloudfront.net/chat-module.js`;

  const {currentUser} = useAuth();
  const [configuration, setConfiguration] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDoc(doc(db, 'configurations', currentUser.uid)).then((doc) => {
      setConfiguration({ id: doc.id, ...doc.data() });
    }, (error) => setError(error));
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`<script src="${librarySource}" clientID="${configuration.id}" clientToken="${configuration.clientToken}"></script>`).then(() => {
      alert('Code snippet copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }

  return (
    <AuthLayout>
      <Grid.Row style={{ flexGrow: 1 }}>
      <Container>
        <Header as='h2' style={{ margin: '20px 0' }}>
          Integration Guide
        </Header>
        <Segment raised>
          <Header as='h3'>Configuration</Header>
          <p>Before you can integrate our JavaScript module into your website, you need to configure it. Please fill out the form below:</p>
          <ConfigurationForm configuration={configuration}/>
        </Segment>
        {
          configuration &&
          <Segment raised>
            <p>To integrate our JavaScript module into your website, simply add the following script tag to the HTML of your webpage:</p>
            <Segment color='blue'>
              <pre>&lt;script
                  src="{librarySource}"
                  clientID="{configuration.id}"
                  clientToken="{configuration.clientToken}"
              &gt;&lt;/script&gt;</pre>
            </Segment>
            <Button color='blue' onClick={copyToClipboard}>
              Copy to Clipboard
            </Button>
          </Segment>
        }
      </Container>
      </Grid.Row>
    </AuthLayout>
  );
}

export default InstructionsPage;
