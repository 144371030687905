import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import axiosInstance from '../services/axios';
import ErrorMessage from './ErrorMessage';

const ClientForm = ({ client }) => {
    const [formData, setFormData] = useState({
        name: client?.name ?? '',
    });
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            if (client) {
            await axiosInstance.put(`/v1/clients/${client.id}`, formData);
            } else {
                await axiosInstance.post(`/v1/clients`, formData);
            }
        } catch (error) {
            setError(error);
        }
        setIsSaving(false);
    }

    return (
        <Form>
            {error && <Form.Field><ErrorMessage error={error} /></Form.Field>}
            <Form.Input
                label='Name'
                name='name'
                placeholder='Name'
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <Button type='submit' primary onClick={handleSubmit} loading={isSaving}>Save</Button>
        </Form>
    )
}
export default ClientForm