import React from 'react';
import { NavLink } from 'react-router-dom';
import { Comment } from 'semantic-ui-react';
import { CUSTOMER_PATH } from '../constants/paths';
import moment from 'moment';

// timestamp is firestore timestamp

const ChatMessage = ({ currentCustomer, currentUser, chatMessage }) => {

    const time = moment(chatMessage.timestamp?.toDate()).fromNow();

    if (currentCustomer && chatMessage.authorID === currentCustomer.id) {
        return (
            <Comment>
                <Comment.Avatar />
                <Comment.Content>
                    <Comment.Author as={NavLink} to={`${CUSTOMER_PATH}/${currentCustomer.id}`} >{currentCustomer.name}</Comment.Author>
                    <Comment.Metadata content={time} />
                    <Comment.Text>{chatMessage.message}</Comment.Text>
                </Comment.Content>
            </Comment>
        );
    }
    return (
        <Comment>
            <Comment.Avatar />
            <Comment.Content>
                <Comment.Author>{currentUser.displayName ?? currentUser.email}</Comment.Author>
                <Comment.Metadata content={time} />
                <Comment.Text>{chatMessage.message}</Comment.Text>
            </Comment.Content>
        </Comment>
    );
}
export default ChatMessage