import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import axiosInstance from '../services/axios';

const CustomerForm = ({ customer }) => {
    const [formData, setFormData] = useState({
        name: customer?.name ?? '',
        email: customer?.email ?? '',
        phone: customer?.phone ?? '',
    });
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            await axiosInstance.put(`/customers/${customer.id}`, formData);
        } catch (error) {
            setError(error);
        }
        setIsSaving(false);
    }

    return (
        <Form>
            <Form.Input
                label='Name'
                name='name'
                placeholder='Name'
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <Form.Input
                label='Email'
                name='email'
                placeholder='Email'
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <Form.Input
                label='Phone'
                name='phone'
                placeholder='Phone'
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
            <Button type='submit' primary onClick={handleSubmit} loading={isSaving}>Save</Button>
        </Form>
    )
}
export default CustomerForm