import React, { useState } from 'react';
import { functions } from '../../services/firebase';
import {Segment, Button, Message} from 'semantic-ui-react';
import ErrorMessage from '../ErrorMessage';
import { httpsCallable } from "firebase/functions";

// Add button that calls a firebase function to create a session url
const UpgradePlanBanner = ({ profile }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const onUpgradeButtonPressed = async () => {
        setIsLoading(true);
        try {
            const priceID = "price_1OwvhrE3oXrUtaVbSSff6Upi"
            const successURL = window.location.href;
            const cancelURL = window.location.href;
            const {data} = await httpsCallable(functions, 'createStripeCheckoutLink')({
                priceID,
                successURL,
                cancelURL
            });
            window.location.href = data;
        } catch (error) {
            console.error('Error upgrading plan', error);
            setError(error);
        }
        setIsLoading(false);
    }

    if (profile && profile.isSubscribed) {
        return null;
    }
    
    return (
        <Segment>
            {error && <ErrorMessage error={error} />}
            <Message
                warning
                header="Upgrade your plan"
                content="You are currently on a free plan. Upgrade to a premium plan to access more features."
            />
            <Button primary loading={isLoading} onClick={onUpgradeButtonPressed}>Upgrade</Button>
        </Segment>
    );
}

export default UpgradePlanBanner;